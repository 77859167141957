import styled from "styled-components"
import { Link } from "gatsby"

export const FeatureTileWrapper = styled.div`
  display: flex;
  width: 30%;
  justify-content: space-between;
  flex-direction: column;
  box-shadow: 0px 10px 20px 4px #00000015;
  padding: 50px;
  @media (max-width: 1000px) {
    width: 100%;
    margin: 50px 0;
  }
`
export const FeatureTileContainer = styled.div`
  max-width: 1900px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    display: block;
  }
`

export const SVGWrapper = styled.div`
  img {
    margin-bottom: 15px;
    height: 50px;
  }
`

export const StyledLink = styled(Link)`
  color: hsl(0, 0%, 100%);
  width: 100%;
  display: block;
  text-align: center;
  padding: 11px 27px;
`

export const ArrowWrapper = styled.div`
  cursor: pointer;
  background-color: #292929;
  width: auto;
  font-family: "Fira Sans", sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  border: 0;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition: 0.2;
  border: solid #fff 0px;
  &:hover {
    box-shadow: 0px 10px 20px 4px #00000035;
  }
  &:hover div {
    transition: 0.2s;
    width: 28px;
  }
`

export const Arrow = styled.div`
  transition: 0.2s;
  width: 14px;
  height: 1px;
  background: #fff;
  right: 20px;
  position: absolute;
  top: 19px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid #0000;
    border-right: 5px solid #0000;
    border-bottom: 5px solid #fff;
    right: -8px;
    transform: rotate(90deg);
    top: -2px;
  }
`

import * as React from 'react'
import {
  FeatureTileWrapper,
  SVGWrapper,
  Arrow,
  FeatureTileContainer,
  ArrowWrapper,
  StyledLink
} from './featureTile.style'
import Laptop from './laptop.svg'
import { Link } from 'gatsby'
import VideoIcon from './video.svg'
import LightBulb from './light-bulb.svg'
import Live from './live.svg'

const workshops = {
  title: 'Workshops',
  description:
    'Join me and many of your fellow developers as I do live remote workshops. All workshops include hands on exercises with answers, live Q&A, and example repositories to follow along with.',
  icon: Laptop,
  url: 'workshops'
}

const courses = {
  title: 'Courses',
  description:
    'As a self-taught developer, I attribute a lot of what I know today to the video courses I watched early on. All of my courses are broken up into small modular lessons, designed to get right to the point. ',
  icon: VideoIcon,
  url: 'courses'
}

// const consulting = {
//   title: '1 on 1',
//   description:
//     "Are you looking for a job and want to practice a mock technical interview? Have a project you need some help with? Or maybe you just want some 1 on 1 help on simple / complex programming topics. Let's chat! ",
//   icon: LightBulb,
//   url: '#'
// }

const live = {
  title: 'Live on Twitch',
  description:
    "I'll be live coding with some of your favorite developers, thought leaders, and inspirational individuals. Click on the button below to check out the upcoming schedule. ",
  icon: Live,
  url: 'live-with-tyler'
}

const postList = [courses, workshops, live]

const FeatureTile = () => {
  return (
    <FeatureTileContainer>
      {postList.map(({ title, description, icon, url }) => (
        <FeatureTileWrapper key={title}>
          <div>
            <SVGWrapper>
              <img src={icon} alt="service icon" />
            </SVGWrapper>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <ArrowWrapper>
            <StyledLink to={url}>Learn more</StyledLink>
            <Arrow />
          </ArrowWrapper>
        </FeatureTileWrapper>
      ))}
    </FeatureTileContainer>
  )
}

export default FeatureTile

import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// import FeaturedCard from "../../../components/FeaturedCard/FeaturedCard"
import FeatureTile from '../../../components/FeatureTile/featureTile'
import {
  FeaturedPostWrapper,
  FeaturedPostRow,
  FeaturedPostCol,
  SecTitle
} from './style'

type FeaturedPostsProps = {}

const FeaturedPosts: React.FunctionComponent<FeaturedPostsProps> = props => {
  return (
    <FeaturedPostWrapper>
      <SecTitle>Work</SecTitle>
      <FeatureTile />
    </FeaturedPostWrapper>
  )
}

export default FeaturedPosts
